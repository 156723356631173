// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"evo:20250126.2-544814"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import { init, addIntegration, browserTracingIntegration } from '@sentry/nextjs';

if (process.env.SENTRY_ENABLED === 'true') {
	console.error('Sentry is enabled!', process.env.BUILD_ENV);
	init({
		dsn: process.env.SENTRY_DSN,

		release: process.env.SENTRY_RELEASE,

		environment: process.env.BUILD_ENV,

		// browserTracingIntegration - Collects browser performance metrics like throughput, latency and the impact of errors.
		// see - https://docs.sentry.io/platforms/javascript/configuration/integrations/browsertracing/
		integrations: [browserTracingIntegration()],

		beforeBreadcrumb: (breadcrumb) => {
			if (breadcrumb.level === 'info' || breadcrumb.level === 'debug' || breadcrumb.level === 'log') {
				return null;
			}
			return breadcrumb;
		},

		// Setting this option to true will print useful information to the console while you're setting up Sentry.
		debug: false,

		// This option is required for capturing headers and cookies. required for httpClientIntegration()
		sendDefaultPii: false,

		// Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
		tracesSampleRate: 0.5,

		replaysSessionSampleRate: 0.3, // let's start with 0.5 for each environment and adjust later

		// Define how likely Replay events are sampled when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		initialScope: (scope) => {
			scope.setTags({ [`evo.tier`]: 'client' });
			return scope;
		},
	});

	async function lazyLoadSentryIntegrations() {
		const { replayIntegration, httpClientIntegration } = await import('@sentry/nextjs');

		// replayIntegration - Session replay builds a video like snapshot of the user session.
		// see - https://docs.sentry.io/platforms/javascript/configuration/integrations/replay/
		addIntegration(replayIntegration());

		// httpClientIntegration - Collects information about HTTP failed requests and attaches request and response info.
		// see - https://docs.sentry.io/platforms/javascript/configuration/integrations/httpclient/
		addIntegration(
			httpClientIntegration({
				failedRequestStatusCodes: [
					[400, 499],
					[500, 599],
				],
			}),
		);
	}

	lazyLoadSentryIntegrations();
}
